import {createTakipiDateTimePicker, createProvideFeedbackDialog} from '../../common/src/index.js';
import {mountTakipiSidePanel} from './sidepanel/SidePanelView';
import {preloadImages} from './ImagePreloader';
import {SidePanelController} from './sidepanel/SidePanelController';
import {SidePanelViewModel} from "./sidepanel/SidePanelViewModel";

window.createDashboardSidePanelController = function(dashboardManager, topBar, presetManager, elementId)
{
	preloadImages().forEach(image => 
	{
		window.fetch(image);
	});
	
	let sidePanelController = new SidePanelController(dashboardManager, topBar, presetManager);
	
	let sidePanelViewModel = new SidePanelViewModel(sidePanelController);
	
	mountTakipiSidePanel(sidePanelViewModel, elementId);
	sidePanelController.init();
	
	return sidePanelController;
};

window.createTakipiDateTimePicker = function(pickersManager, dashboardManager, popupContainerEl)
{
	return createTakipiDateTimePicker(pickersManager, dashboardManager, popupContainerEl, true);
};

window.createProvideFeedbackDialog = function(feedbackManager, containerId, modalApi)
{
	return createProvideFeedbackDialog(feedbackManager, containerId, modalApi);
};
