import moment from 'moment';

export class DateTimePickerController
{
	constructor(dateTimePickerViewModel, pickersManager, dashboardManager, createRefreshRateEl)
	{
		this.viewModel = dateTimePickerViewModel;
		this.pickersManager = pickersManager;
		this.dashboardManager = dashboardManager;
		
		this.createRefreshRateEl = createRefreshRateEl;
	}
	
	init()
	{
		this.initViewModel();
	}
	
	initViewModel()
	{
		this.viewModel.reset();
		
		this.viewModel.setOnApplyButtonClickedHandler(this.saveSettings.bind(this));
		this.viewModel.setOnCancelButtonClickedHandler(this.cancelChanges.bind(this));
		
		this.viewModel.setHours();
		this.viewModel.setFromTime(this.getFromMoment());
		this.viewModel.setToTime(this.getToMoment());
		
		this.viewModel.setTimeOptions(this.getTimeOptions());
		this.viewModel.setSelectedTimeOption(this.getCurrentTimeOption());
		
		this.viewModel.setCreateRefreshRateEl(this.createRefreshRateEl);
		
		if (this.createRefreshRateEl)
		{
			this.viewModel.setRefreshRates(this.getRefreshRates());
			this.viewModel.setSelectedRefreshRate(this.getCurrentRefreshRate());
		}
	}
	
	getFromMoment()
	{
		return moment(this.pickersManager.getCustomTimeOption().start);
	}
	
	getToMoment()
	{
		return moment(this.pickersManager.getCustomTimeOption().end);
	}
	
	getTimeOptions()
	{
		return this.pickersManager.getTimeOptions(false, true);
	}
	
	getCurrentTimeOption()
	{
		return this.pickersManager.getCurrentTimeOption();
	}
	
	doGetOptionTimeFrame(timePickerValue)
	{
		return this.pickersManager.doGetOptionTimeFrame(timePickerValue);
	}
	
	getRefreshRates()
	{
		return this.pickersManager.getRefreshRates();
	}
	
	getCurrentRefreshRate()
	{
		return this.dashboardManager.getCurrentRefreshRate();
	}
	
	saveSettings()
	{
		if (this.createRefreshRateEl)
		{
			this.updateRefreshRate();
		}
		
		if (this.viewModel.getCustomTimeOptionSelected())
		{
			this.setCustomTimeFrame();
		}
		
		this.updateTimeFrame();
		
		this.pickersManager.closeDatePickerPopup();
	}
	
	cancelChanges()
	{
		this.pickersManager.closeDatePickerPopup();
	}
	
	updateRefreshRate()
	{
		this.pickersManager.adjustRefreshRate(this.viewModel.selectedRefreshRate.value);
	}
	
	setCustomTimeFrame()
	{
		this.pickersManager.setCustomTimeframe(
				this.viewModel.fromTime.toDate().getTime(),
				this.viewModel.toTime.toDate().getTime(),
				true);
	}
	
	updateTimeFrame()
	{
		let selectedTimeOption = this.viewModel.getSelectedTimeOption();
		
		if (!selectedTimeOption)
		{
			this.pickersManager.setWantedTime(TakipiPickersTimeframeOptions.CUSTOM, true);
		}
		else
		{
			this.pickersManager.setWantedTime(selectedTimeOption.name, true);
		}
	}
}
