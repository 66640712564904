import alert from './sidepanel/img/22_alert.svg';
import edit from './sidepanel/img/24_edit.svg';
import trash from './sidepanel/img/25_trash.svg';
import arrow_up from './sidepanel/img/26_arrow_up.svg';
import arrow_down from './sidepanel/img/27_arrow_down.svg';
import undo_share from './sidepanel/img/28_undo_share.svg';
import share from './sidepanel/img/29_share.svg';
import duplicate from  './sidepanel/img/30_duplicate.svg';
import auto_hide from './sidepanel/img/52_autoHideTrash.svg';
import admin from './sidepanel/img/54_admin.svg';

export function preloadImages() {
	return [alert, edit,trash, arrow_up, arrow_down, undo_share, share, duplicate, auto_hide, admin];
}
