export class SidePanelController
{
	constructor(dashboardManager, topBar, presetManager)
	{
		this.dashboardManager = dashboardManager;
		this.topBar = topBar;
		this.presetManager = presetManager;
		
		this.alertSettings = this.dashboardManager.alertSettings;
		
		this.presetDatas = [];
		
		this.isShowMoreCollapsed = false;
		this.selectedBadgeName = "";
		this.selectedPresetData = null;
		this.isSelectedPresetModified = false;
		this.activeContextMenuPresetId = "";
		this.sections = [];
		
		this.isUserViewer = false;
	}
	
	init()
	{
		EventManagerInst.addEventListener(this.presetManager, "AVAILABLE_PRESETS_CHANGED", this.availablePresetsChanged, this);
		
		EventManagerInst.addEventListener(this.presetManager, "APPLIED_LOADED_PRESET", this.appliedLoadedPreset, this);
		
		EventManagerInst.addEventListener(this.presetManager, "APPLIED_PRESET_SAVED",	this.appliedPresetInteraction, this);
		EventManagerInst.addEventListener(this.presetManager, "APPLIED_PRESET_CLEARED",	this.appliedPresetInteraction, this);
		EventManagerInst.addEventListener(this.presetManager, "APPLIED_PRESET_CHANGED",	this.appliedPresetInteraction, this);
		
		EventManagerInst.addEventListener(this.presetManager, "FLASH_PRESET_CLEARED",	this.appliedPresetInteraction, this);
		
		EventManagerInst.addEventListener(this.alertSettings, "ALERT_SETTINGS_PRESETS_UPDATED", this.alertSettingsPresetsUpdated, this);
	}
	
	setIsShowMoreCollapsed = (isShowMoreCollapsed) =>
	{
		this.isShowMoreCollapsed = isShowMoreCollapsed;
		
		if (isShowMoreCollapsed)
		{
			EventManagerInst.dispatch(this, "SHOW_MORE_COLLAPSED");
		}
		else
		{
			EventManagerInst.dispatch(this, "SHOW_MORE_EXPANDED");
		}
	};
	
	getSelectedBadgeName = () => 
	{
		return this.selectedBadgeName;
	};
	
	setSelectedBadgeName = (selectedBadgeName) =>
	{
		this.selectedBadgeName = selectedBadgeName;
		
		EventManagerInst.dispatch(this, "SELECTED_BADGE_CHANGED");
	};
	
	getSelectedPresetData = () => 
	{
		return this.selectedPresetData;
	};
	
	setSelectedPresetData = (selectedPresetData) =>
	{
		this.selectedPresetData = selectedPresetData;
		
		EventManagerInst.dispatch(this, "SELECTED_PRESET_CHANGED");
	};
	
	getIsSelectedPresetModified = () => 
	{
		return this.isSelectedPresetModified; 
	};
	
	setIsSelectedPresetModified = (isSelectedPresetModified) =>
	{
		this.isSelectedPresetModified = isSelectedPresetModified;
		
		EventManagerInst.dispatch(this, "SELECTED_PRESET_MODIFIED_CHANGED");
	};
	
	getActiveContextMenuPresetId = () => 
	{
		return this.activeContextMenuPresetId;
	};
	
	setActiveContextMenuPresetId = (activeContextMenuPresetId) =>
	{
		this.activeContextMenuPresetId = activeContextMenuPresetId;
		
		EventManagerInst.dispatch(this, "ACTIVE_MENU_CHANGED");
	};
	
	getSections = () =>
	{
		return this.sections;
	};
	
	setSections = (sections) =>
	{
		this.sections = sections;
		
		EventManagerInst.dispatch(this, "SECTIONS_CHANGED");
	};
	
	getIsUserViewer = () =>
	{
		return this.isUserViewer;
	};
	
	setIsUserViewer = (isUserViewer) =>
	{
		this.isUserViewer = isUserViewer;
		
		EventManagerInst.dispatch(this, "IS_USER_VIEWER_SET");
	};
	
	setExpandedSectionName = (sectionName) =>
	{
		this.sections.forEach(section => {
			section.isCollapsed = (section.name !== sectionName);
		});
		
		EventManagerInst.dispatch(this, "SECTIONS_CHANGED");
	};
	
	serviceChanged()
	{
		EventManagerInst.dispatch(this, "SERVICE_CHANGED");
		
		this.reloadPresets();
	}
	
	availablePresetsChanged()
	{
		this.reloadPresets();
	}
	
	alertSettingsPresetsUpdated()
	{
		this.reloadPresets();
	}
	
	reloadPresets()
	{
		this.createPresetDatas();
		
		this.buildSections();
		
		this.selectLastAppliedPreset();
	}
	
	createPresetDatas()
	{
		this.presetDatas = [];
		
		let presets = this.getServicePresets();
		
		for (let i = 0; i < presets.length; i++)
		{
			this.presetDatas.push(
					new SidePanelPresetData(presets[i], new TakipiAlertSettingsReflector(this.alertSettings)));
		}
	}
	
	getServicePresets()
	{
		let presets = this.presetManager.getRemotePresets();
		
		if (!presets)
		{
			return [];
		}
		
		let currentService = this.dashboardManager.currentService;
		
		presets = presets.filter((preset) => {
			return ((preset.canUsePreset(currentService)) &&
					(!preset.isInvisible()));
		});
		
		presets.sort((p1, p2) => {
			if ((p1.isAdminPreset()) &&
					(!p2.isAdminPreset()))
			{
				return 1;
			}
			else if ((p2.isAdminPreset()) &&
					(!p1.isAdminPreset()))
			{
				return -1;
			}
			
			return takipiNaturalStringCompare(p1.displayName, p2.displayName);
		});
		
		return presets;
	}
	
	selectLastAppliedPreset()
	{
		let selectedPresetName = this.presetManager.getLastAppliedPresetName();
		let presetToSelect = this.getPresetByName(selectedPresetName, true);
		
		if ((!presetToSelect) &&
			(!selectedPresetName))
		{
			presetToSelect = this.getDefaultPresetData();
		}
		
		if ((presetToSelect) &&
			(selectedPresetName !== TAKIPI_LABEL_AS_PRESET_NAME))
		{
			this.selectView(presetToSelect, this.loadLastSelectedBadgeName(), false, null);
		}
	};
	
	buildSections()
	{
		// GUY: this function handles everything except for selecting a preset, call it when in doubt.
		//
		let badgeOrder = getInternalFieldOrElse(
				this.dashboardManager, ["currentService", "userServiceSettings", "badgeOrder"], []);
		
		let showMoreIndex = badgeOrder.indexOf(DefaultBadgeIds.BADGES_DEFAULT_SHOWMORE);
		
		let badgesMap = this.presetManager.getBadgeMap();
		
		if (Object.values(badgesMap).length === 0)
		{
			return;
		}
		
		Object.values(badgesMap).forEach(badge => badge.presets = []);
		
		let favoritePresetIds = this.presetManager.getFavoritePresetIds();
		
		// Link presets and badges
		//
		this.presetDatas.forEach(presetData => {
			let preset = presetData.preset;
			
			if (preset.badgeIds)
			{
				preset.badgeIds.forEach(badgeId => {
					if (badgesMap[badgeId])
					{
						badgesMap[badgeId].presets.push(presetData);
					}
				});
			}
			
			if (favoritePresetIds.contains(preset.backendId))
			{
				badgesMap[DefaultBadgeIds.BADGES_DEFAULT_FAVORITES_ID].presets.push(presetData);
				
				presetData.isFavorite = true;
			}
			else 
			{
				presetData.isFavorite = false;
			}
		});
		
		// Sort Favorites
		//
		badgesMap[DefaultBadgeIds.BADGES_DEFAULT_FAVORITES_ID].presets.sort((p1, p2) => {
			return ((favoritePresetIds.indexOf(p1.preset.backendId) > favoritePresetIds.indexOf(p2.preset.backendId)) ? 1 : -1);
		});
		
		// Add custom actions
		//
		Object.values(badgesMap).forEach(badge => {
			let customAction = this.getSectionCustomAction(badge.id, badge.name);
			
			if (customAction)
			{
				badge.presets.push(customAction);
			}
		});
		
		// Remove badges with no presets (except Show More)
		// GUY: whoever deletes this for any reason - make sure no empty badge nodes,
		// like labels or custom categories, are created in the tree - they mess-up the dragging
		//
		let sections = Object.values(badgesMap).filter((badge) => {
			return ((badge.presets.length > 0) ||
					(badge.id === DefaultBadgeIds.BADGES_DEFAULT_SHOWMORE));
		});
		
		const showmoreCompareIndex = showMoreIndex - 1;
		
		// Sort badges
		//
		sections.sort((a, b) => {
			const aIndex = badgeOrder.indexOf(a.id);
			const bIndex = badgeOrder.indexOf(b.id);
			
			if (aIndex === -1)
			{
				// if showmore index is greater than bindex than set bindex < aindex - (positive result)
				return showmoreCompareIndex - bIndex;
			}
			
			if (bIndex === -1)
			{
				// if aIndex is greater than showmore index than set bindex < aindex - (positive result)
				return aIndex - showmoreCompareIndex;
			}
			
			return ((badgeOrder.indexOf(a.id) > badgeOrder.indexOf(b.id)) ? 1 : -1);
		});
		
		// Handle misc badge data
		//
		let expandedSectionTitle = this.loadExpandedSectionTitle();
		
		sections.forEach((badge, index) => {
			badge.isUnderShowMore = (index > showMoreIndex);
			badge.isCollapsed = (badge.name !== expandedSectionTitle);
			
			switch (badge.id)
			{
				case DefaultBadgeIds.BADGES_DEFAULT_SHOWMORE:
					badge.type = SectionTypes.SHOW_MORE_TYPE_NAME;
					break;
				default:
					badge.type = SectionTypes.VIEW_TYPE_NAME;
			}
		});
		
		this.setSections(sections);
		
		this.setIsShowMoreCollapsed(this.loadIsShowMoreCollapsed());
		
		this.setIsUserViewer(this.dashboardManager.isCurrentUserViewer());
		
		this.validateSelectedBadge();
	}
	
	getFavoritePresetIds()
	{
		return getInternalFieldOrElse(
				this, ["dashboardManager", "currentService", "userServiceSettings", "favoritePresets"], []).slice(0);
	}
	
	getSectionCustomAction(sectionId, sectionTitle)
	{
		let openChronosSettingsDialog = () => {
			this.dashboardManager.dialogsManager.openChronosSettingsDialog(null, null, null, "side panel");
		};
		
		let openSavePrivateViewDialog = () => {
			this.presetManager.saveView("side panel", true);
		};
		
		let openSavePublicViewDialog = () => {
			this.presetManager.saveView("side panel", false);
		};
		
		let onClick, text;
		
		switch (sectionId)
		{
			case DefaultBadgeIds.BADGES_DEFAULT_TIMERS_ID:
				onClick = getInvokerAsClickFunction(openChronosSettingsDialog, this);
				text = "Add Timer";
				break;
			case DefaultBadgeIds.BADGES_DEFAULT_MY_VIEWS_ID:
				onClick = getInvokerAsClickFunction(openSavePrivateViewDialog, this);
				text = "Save View";
				break;
			case DefaultBadgeIds.BADGES_DEFAULT_TEAM_VIEWS_ID:
				onClick = getInvokerAsClickFunction(openSavePublicViewDialog, this);
				text = "Save View";
				break;
			default:
				return null;
		}
		
		return new SidePanelCustomActionData(onClick, text, toCamelCase(sectionTitle));
	}
	
	validateSelectedBadge()
	{
		// This function checks that the selected preset is inside the selected badge
		// and otherwise selects another suitable badge
		//
		let selectedPresetData = this.selectedPresetData;
		
		if (!selectedPresetData)
		{
			return;
		}
		
		let selectedBadgeName = this.selectedBadgeName;
		
		if ((selectedPresetData.isFavorite) &&
			(selectedBadgeName === DefaultBadgeNames.BADGES_DEFAULT_FAVORITES))
		{
			return;
		}
		
		let selectedPreset = selectedPresetData.preset;
		
		if ((this.isPresetAlwaysInFavorites(selectedPreset)) &&
			(safeIsEmpty(selectedPreset.badgeIds)))
		{
			this.setSelectedBadgeName(DefaultBadgeNames.BADGES_DEFAULT_FAVORITES);
			this.saveLastSelectedBadgeName(DefaultBadgeNames.BADGES_DEFAULT_FAVORITES);
			
			return;
		}
		
		let shouldSelectBadge = true;
		
		selectedPreset.badgeIds.forEach(badgeId => {
			if (selectedBadgeName === this.presetManager.getBadgeById(badgeId).name)
			{
				shouldSelectBadge = false;
			}
		});
		
		if (shouldSelectBadge)
		{
			let badgeToSelect = this.getPresetBadgeForSelection(selectedPreset);
			
			this.setSelectedBadgeName(badgeToSelect.name);
			this.saveLastSelectedBadgeName(badgeToSelect.name);
		}
	}
	
	getPresetBadgeForSelection(preset)
	{
		if ((this.isPresetAlwaysInFavorites(preset)) ||
			(safeIsEmpty(preset.badgeIds)))
		{
			return this.presetManager.getBadgeById(DefaultBadgeIds.BADGES_DEFAULT_FAVORITES_ID);
		}
		
		let badgeId = preset.badgeIds.find(badgeId =>
				!SECONDARY_SELECTION_BADGES.contains(badgeId)
		);
		
		// GUY: we shouldn't get here without badgeId, but when we do we arbitrarily select the
		// first of 'preset.badgeIds' which contains one or more SECONDARY_SELECTION_BADGES
		//
		if (!badgeId)
		{
			badgeId = preset.badgeIds[0];
		}
		
		return this.presetManager.getBadgeById(badgeId);
	}
	
	expandRelevantSection(preset, sectionName)
	{
		if (this.isPresetAlwaysInFavorites(preset))
		{
			this.setExpandedSectionName(DefaultBadgeNames.BADGES_DEFAULT_FAVORITES);
		}
		else if (preset.badgeIds)
		{
			let badge = this.getPresetBadgeForSelection(preset);
			let badgeName = badge.name;
			
			if (sectionName === badgeName)
			{
				if (badge.isUnderShowMore)
				{
					this.setIsShowMoreCollapsed(false);
				}
				
				this.setExpandedSectionName(badgeName);
			}
		}
	}
	
	selectFallbackPreset()
	{
		if (this.presetDatas.length === 0)
		{
			return;
		}
		
		let presetDataToSelect = this.getDefaultPresetData() || this.presetDatas[0];
		
		let badge = this.getPresetBadgeForSelection(presetDataToSelect.preset);
		
		this.selectView(presetDataToSelect, badge.name, true);
	}
	
	appliedPresetInteraction()
	{
		this.refreshSelectedViewModifiedState();
	}
	
	refreshSelectedViewModifiedState()
	{
		if (this.selectedPresetData)
		{
			this.setIsSelectedPresetModified(!this.presetManager.isUnmodifiedFlashPreset());
		}
		
		this.buildSections();
	};
	
	appliedLoadedPreset(event, preset)
	{
		this.selectPreset(preset, false);
	}
	
	// GUY: don't be confused by this function name...
	// it is called by presetManager after a preset was applied (applyPreset is false)
	// or by UrlManager when it wants to apply a preset (applyPreset is true)
	//
	// selectView is the function for internal use. 
	//
	selectPreset(preset, applyPreset)
	{
		let badgeName;
		let lastSelectedBadgeName = this.loadLastSelectedBadgeName();
		
		if (this.isPresetAlwaysInFavorites(preset))
		{
			badgeName = DefaultBadgeNames.BADGES_DEFAULT_FAVORITES;
		}
		else if (lastSelectedBadgeName)
		{
			badgeName = lastSelectedBadgeName;
		}
		else
		{
			badgeName = this.getPresetBadgeForSelection(preset).name;
		}
		
		this.selectPresetByName(preset.presetName, badgeName, applyPreset);
	}
	
	selectPresetByName(presetName, badgeName, applyPreset)
	{
		let presetData = this.getPresetByName(presetName);
		
		if (!presetData)
		{
			return false;
		}
		
		if (presetData !== this.selectedPresetData)
		{
			this.selectView(presetData, badgeName, applyPreset, null);
		}
		
		return true;
	}
	
	getPresetByName(presetName, getByDisplayName)
	{
		for (let i = 0; i < this.presetDatas.length; i++)
		{
			let presetData = this.presetDatas[i];
			
			let nameToCompare = (getByDisplayName ?
								 presetData.preset.displayName :
								 presetData.preset.presetName);
			
			if (nameToCompare === presetName)
			{
				return presetData;
			}
		}
	}
	
	selectView(presetData, badgeName, applyPreset, postSelectAction)
	{
		this.setSelectedPresetData(presetData);
		this.setSelectedBadgeName(badgeName);
		this.saveLastSelectedBadgeName(badgeName);
		
		if (!this.loadExpandedSectionTitle())
		{
			this.expandRelevantSection(presetData.preset, badgeName);
		}
		
		this.validateSelectedBadge();
		
		// Dor - applying a preset is a time consuming action, the timeout is to allow the UI to refresh itself.
		//
		setTimeout(() => {
			if (applyPreset)
			{
				let presetName = presetData.preset.presetName;
				
				this.presetManager.applyPreset(presetName);
			}
			
			this.refreshSelectedViewModifiedState();
			
			if (postSelectAction)
			{
				postSelectAction();
			}
		}, 20);
	}
	
	isPresetAlwaysInFavorites(preset)
	{
		if (!preset.displayName)
		{
			return false;
		}
		
		return !!ALWAYS_FAVORITE_PRESETS.find(favPreset =>
				((preset.displayName) && (favPreset.toLowerCase() === preset.displayName.toLowerCase()))
		);
	}
	
	getDefaultPresetData()
	{
		let defaultPresetName = this.presetManager.getDefaultPresetName();
		
		return this.getPresetByName(defaultPresetName, true);
	}
	
	getNameForTitle()
	{
		let presetTitle = this.presetManager.getNameForTitle();
		
		return presetTitle || "";
	}
	
	onResize()
	{
		// do nothing - referenced from v1
	}
	
	/*
	 * ****************************************
	 * Section collapsing and selection
	 * ***************************************
	 */
	
	toggleSection(sectionName, wasCollapsed)
	{
		let expandedSectionTitle = (wasCollapsed ? sectionName : null);
		
		this.setExpandedSectionName(expandedSectionTitle);
		
		// GUY: Mini-hack to close context menu upon collapsing
		//
		this.setActiveContextMenuPresetId("");
		
		this.saveExpandedSectionTitle(expandedSectionTitle);
		
		this.reportMixpanel(sectionName, wasCollapsed);
	}
	
	toggleShowMore(wasCollapsed)
	{
		let isShowMoreCollapsed = !wasCollapsed;
		
		this.setIsShowMoreCollapsed(isShowMoreCollapsed);
		
		this.saveIsShowMoreCollapsed(isShowMoreCollapsed);
		
		this.reportMixpanel("Show More", wasCollapsed);
	}
	
	reportMixpanel(sectionTitle, wasCollapsed)
	{
		let mpReportName = (wasCollapsed ? "Expand" : "Collapse") + " side panel section";
		
		mixpanelTrack(mpReportName, {
			"Section": sectionTitle
		});
	}
	
	saveExpandedSectionTitle(expandedSectionTitle)
	{
		LocalStorageInst.setItem(this.getExpandedSectionStorageName(), expandedSectionTitle);
	}
	
	loadExpandedSectionTitle()
	{
		let result = LocalStorageInst.getItem(this.getExpandedSectionStorageName());
		
		return (result === "null" ? null : result);
	}
	
	getExpandedSectionStorageName()
	{
		let serviceId = getInternalFieldOrElse(this, ["dashboardManager", "currentService", "serviceId"], "");
		
		return "takipi_" + serviceId + "_expanded_section_title";
	}
	
	saveIsShowMoreCollapsed(isCollapsed)
	{
		LocalStorageInst.setItem(this.getIsShowMoreCollapsedStorageName(), isCollapsed);
	}
	
	loadIsShowMoreCollapsed()
	{
		let result = LocalStorageInst.getItem(this.getIsShowMoreCollapsedStorageName());
		
		return (result === "true");
	}
	
	getIsShowMoreCollapsedStorageName()
	{
		let serviceId = getInternalFieldOrElse(this, ["dashboardManager", "currentService", "serviceId"], "");
		
		return "takipi_" + serviceId + "_is_show_more_collapsed";
	}
	
	saveLastSelectedBadgeName(badgeName)
	{
		if (badgeName)
		{
			getLocalStorage().setItem(this.getBadgeStorageName(), badgeName);
		}
		else
		{
			this.clearLastSelectedBadgeName();
		}
	}
	
	loadLastSelectedBadgeName()
	{
		return getLocalStorage().getItem(this.getBadgeStorageName());
	}
	
	clearLastSelectedBadgeName()
	{
		getLocalStorage().removeItem(this.getBadgeStorageName());
	}
	
	getBadgeStorageName()
	{
		let serviceId = getInternalFieldOrElse(this, ["dashboardManager", "currentService", "serviceId"], "");
		
		return "takipi_" + serviceId + "_lastSelectedBadgeName";
	}
	
	/*
	 * ****************************************
	 * Event Handlers
	 * ***************************************
	 */
	
	updateBadgesOrder(badgesOrder)
	{
		this.dashboardManager.currentService.userServiceSettings.updateBadgesOrder(badgesOrder);
		
		this.buildSections();
	};
	
	favoritesOrderChanged(newFavoritesOrder)
	{
		if ((takipiIsArray(newFavoritesOrder)) &&
			(newFavoritesOrder.length > 0))
		{
			let rowsMoved = this.dashboardManager.currentService.userServiceSettings.updateFavoritePresets(newFavoritesOrder);
			
			let mpData;
			
			if ((isNumber(rowsMoved)) &&
				(rowsMoved > -1))
			{
				mpData = {
					"Number of rows moved": rowsMoved
				};
			}
			
			mixpanelTrack("Favorites reordered", mpData);
		}
		
		this.buildSections();
	}
	
	presetClicked(presetData, badgeName)
	{
		// DDD - Hotfix 2016/04/21
		// This causes the grid to not be aware of the changes, and it won't free until it reloads.
		// This should only freeze the grid if there's a new data load that's the result of the change.
		//
		// self.dashboardManager.gridManager.currentGridHolder.renderManager.freeze();
		
		this.dashboardManager.clearSearchInput();
		
		this.selectView(presetData, badgeName, true, null);
		
		let preset = presetData.preset;
		
		marketoTrack("Preset Selected", {"preset": preset.displayName});
		mixpanelTrack("View/alert/hide selected", {
			"name": preset.displayName,
			"type": this.presetManager.calcPresetFullType(preset)
		});
	}
	
	viewEditClicked(presetData)
	{
		this.doViewEditClicked(presetData, false);
	}
	
	viewAlertSettingsClicked(presetData)
	{
		this.doViewEditClicked(presetData, true);
	}
	
	doViewEditClicked(presetData, gotoSettingsStep)
	{
		this.editView(presetData.preset, gotoSettingsStep, "side panel");
	}
	
	editView(preset, gotoSettingsStep, source)
	{
		this.dashboardManager.presetManager.editView(preset, gotoSettingsStep, source, true);
	}
	
	toggleFavoriteClicked(presetData)
	{
		let favoritePresetIds = this.presetManager.getFavoritePresetIds();
		
		let presetId = presetData.preset.backendId;
		
		if (presetData.isFavorite)
		{
			favoritePresetIds.splice(favoritePresetIds.indexOf(presetId), 1);
			
			// When un-favoriting the selected preset in favorites badge, select it in another badge
			//
			if (presetData === this.selectedPresetData)
			{
				let presetBadgeIds = getInternalFieldOrElse(presetData, ["preset", "badgeIds"], [null]);
				let newBadgeId = presetBadgeIds[presetBadgeIds.length - 1];
				let newBadgeName = this.presetManager.getBadgeName(newBadgeId);
				
				this.selectView(presetData, newBadgeName, true, null);
			}
		}
		else
		{
			favoritePresetIds.push(presetId);
		}
		
		this.dashboardManager.currentService.userServiceSettings.updateFavoritePresets(favoritePresetIds);
		
		this.buildSections();
	}
	
	viewDuplicateClicked(presetData)
	{
		this.presetManager.duplicatePreset(presetData.preset);
	}
	
	viewShareClicked(presetData)
	{
		let preset = presetData.preset;
		
		let shouldShare = (presetData.presetType === "private");
		
		let username = getInternalFieldOrElse(this.dashboardManager, ["currentUser", "username"], null);
		
		if ((!shouldShare) &&
			(preset.owner !== username))
		{
			let message = "This view was added by " + preset.owner + ", and cannot be set private.<br>" +
						  "An identical view was created, and set as private.";
			
			this.dashboardManager.dialogsManager.openMessageDialog(message, null, "darkestDialog", true, true);
			
			this.presetManager.duplicatePreset(preset, true);
			
			return;
		}
		
		preset.presetType = (shouldShare ? TakipiPresetTypes.SERVICE : TakipiPresetTypes.SERVICE_PRIVATE);
		
		let onSaveSuccess = () =>
		{
			
			presetData.updatePresetInfo();
			
			if (shouldShare)
			{
				this.setSelectedBadgeName(DefaultBadgeNames.BADGES_DEFAULT_TEAM_VIEWS);
				this.saveLastSelectedBadgeName(DefaultBadgeNames.BADGES_DEFAULT_TEAM_VIEWS);
			}
			else
			{
				this.setSelectedBadgeName(DefaultBadgeNames.BADGES_DEFAULT_MY_VIEWS);
				this.saveLastSelectedBadgeName(DefaultBadgeNames.BADGES_DEFAULT_MY_VIEWS);
			}
			
			this.buildSections();
		};
		
		let onSaveFailure = (backendFailure, errorMessage) =>
		{
			errorMessage = errorMessage || "An error occurred. Please try again later.";
			
			this.dashboardManager.dialogsManager.openMessageDialog(errorMessage,
					null,
					"darkestDialog",
					true,
					true);
		};
		
		this.presetManager.fullSave(preset.presetName,
				{
					"succeed": onSaveSuccess,
					"error": onSaveFailure
				},
				false,
				null);
	}
	
	viewDeleteClicked(presetData)
	{
		let preset = presetData.preset;
		let presetDisplayName = preset.displayName;
		
		let doDelete = () =>
		{
			mixpanelTrack("View/alert/hide deleted", {
				"Type": this.dashboardManager.presetManager.calcPresetFullType(preset)
			});
			
			this.presetManager.removePreset(preset.presetName, {}, true);
		};
		
		let bodyHtml = "Are you sure you want to delete the view \"" + escapeHtml(presetDisplayName) + "\"?";
		
		this.dashboardManager.dialogsManager.openYesNoDialog(bodyHtml, "No", "Yes", doDelete, null, "", null, true);
	};
	
	viewHideExistingClicked(presetData)
	{
		this.dashboardManager.presetManager.archiveExistingEvents(presetData.preset);
	};
}


class SidePanelCustomActionData
{
	constructor(onClick, text, className)
	{
		this.onClick = onClick;
		this.text = text;
		this.className = className;
		
		this.leaf = true;
		this.isAction = true;
	}
}


class SidePanelPresetData
{
	constructor(preset, alertSettingsReflector)
	{
		this.preset = preset;
		this.alertSettingsReflector = alertSettingsReflector;
		this.leaf = true;
		
		this.isFavorite = false;
		
		this.ruleType = null;
		this.presetType = null;
		this.hasAlert = false;
		this.isImmutable = false;
		
		this.updatePresetInfo();
	}
	
	updatePresetInfo()
	{
		let ruleType = (this.preset.isAutoArchive() ? "autoArchive" : "alert");
		
		let presetType = "";
		
		if (this.preset.isDefaultPreset())
		{
			presetType = "default";
		}
		else if (this.preset.isAdminPreset())
		{
			presetType = "admin";
		}
		else if (this.preset.isTeamPreset())
		{
			presetType = "public";
		}
		else
		{
			presetType = "private";
		}
		
		let shouldAlertOnNew = this.alertSettingsReflector.alertOnNew(this.preset.backendId);
		
		let periodicUdfEnabled = this.alertSettingsReflector.periodicUdfEnabled(this.preset.backendId);
		let libraryId = this.alertSettingsReflector.periodicUdfLibraryId(this.preset.backendId);
		let functionId = this.alertSettingsReflector.periodicUdfFunctionId(this.preset.backendId);
		
		let hasAnomaly = ((periodicUdfEnabled) && (!!libraryId) && (!!functionId));
		
		this.ruleType = ruleType;
		this.presetType = presetType;
		this.hasAlert = ((ruleType === "alert") && ((shouldAlertOnNew) || (hasAnomaly)));
		this.isImmutable = this.preset.immutable;
	}
	
	canToggleFavorites()
	{
		return !ALWAYS_FAVORITE_PRESETS.find(favPreset => 
				(favPreset.toLowerCase() === this.preset.displayName.toLowerCase())
		);
	}
	
	hasTimeframeFilter()
	{
		return !!getInternalFieldOrElse(this.preset, ["cachedPreset", "lastSeen"], false);
	}
	
	hasLabelFilter()
	{
		let presetLabels = getInternalFieldOrElse(this.preset, ["cachedPreset", "labels", "selectedLabels"], false);
		
		return (presetLabels ? (presetLabels !== "Inbox") : false);
	}
}
