import {observable, action} from 'mobx';
import moment from 'moment';

export class DateTimePickerViewModel
{
	// Observables
	//
	@observable fromTime;
	@observable toTime;
	@observable hours;
	@observable timeOptions;
	@observable customPartSelected;
	@observable createRefreshRateEl;
	@observable refreshRates;
	@observable selectedRefreshRate;
	
	@observable datePickerFromDisplayed;
	@observable datePickerToDisplayed;
	
	@observable isMessageShown;
	
	// Handlers
	//
	onApplyButtonClickedHandler;
	onCancelButtonClickedHandler;
	
	constructor()
	{
		this.reset();
	}
	
	reset()
	{
		this.fromTime = moment();
		this.toTime = moment();
		this.hours = [];
		this.timeOptions = [];
		this.customPartSelected = false;
		this.refreshRates = [];
		this.selectedRefreshRate = null;
		
		this.datePickerFromDisplayed = false;
		this.datePickerToDisplayed = false;
		
		this.isMessageShown = false;
		
		this.onApplyButtonClickedHandler = this.getEmptyHandler("onApplyButtonClickedHandler");
		this.onCancelButtonClickedHandler = this.getEmptyHandler("onCancelButtonClickedHandler");
	}
	
	getEmptyHandler(handlerName)
	{
		return () => {
			TakipiRavenInst.captureMessage("SidePanel: " + handlerName + " was called before it was set");
		}
	}
	
	// Setters + Getters
	//
	setHours()
	{
		this.hours = [];
		
		let myMoment = moment.utc(0);
		
		for (let i = 0; i < 48; i++)
		{
			this.hours.push(this.momentToHoursDropdownElement(myMoment.clone()));
			
			myMoment.add(30, 'minutes');
		}
	}
	
	momentToHoursDropdownElement(myMoment)
	{
		return {
			value: myMoment,
			label: myMoment.format("h:mm A")
		};
	}
	
	roundToNearestHalfHour(myMoment, roundUp)
	{
		if ((myMoment.minutes() > 0) &&
			(myMoment.minutes() < 30))
		{
			if (roundUp)
			{
				myMoment.minutes(30);
			}
			else
			{
				myMoment.minutes(0);
			}
		}
		else if (myMoment.minutes() > 30)
		{
			if (roundUp)
			{
				myMoment.minutes(0).add(1, 'hour');
			}
			else
			{
				myMoment.minutes(30);
			}
		}
		
		return myMoment.seconds(0);
	}
	
	getFromTime()
	{
		return this.fromTime;
	}
	
	getToTime()
	{
		return this.toTime;
	}
	
	@action setFromTime(fromTime)
	{
		let roundedFromTime = this.roundToNearestHalfHour(fromTime, false);
		
		if (roundedFromTime > this.toTime)
		{
			this.displayMessageTemporarily(5000);
			
			return;
		}
		
		this.toggleMessageVisibility(false);
		
		this.fromTime = roundedFromTime;
	}
	
	@action setToTime(toTime)
	{
		let roundedToTime = this.roundToNearestHalfHour(toTime, true);
		
		if (roundedToTime < this.fromTime)
		{
		 	this.displayMessageTemporarily(5000);
		 	
			return;
		}
		
		this.toggleMessageVisibility(false);
		 
		this.toTime = roundedToTime;
	}
	
	@action toggleMessageVisibility(isVisible)
	{
		this.isMessageShown = isVisible;
	}
	
	displayMessageTemporarily(timeout)
	{
		this.toggleMessageVisibility(true);
		
		setTimeout(() => {
			this.toggleMessageVisibility(false);
		}, timeout);
	}
	
	fromHourChanged = (hourMoment) =>
	{
		let updatedFromTime = this.getFromTime().clone().hours(hourMoment.hours()).minutes(hourMoment.minutes());
		
		this.setFromTime(updatedFromTime);
	};
	
	toHourChanged = (hourMoment) =>
	{
		let updatedToTime = this.getToTime().clone().hours(hourMoment.hours()).minutes(hourMoment.minutes());
		
		this.setToTime(updatedToTime);
	};
	
	@action setTimeOptions(timeOptions)
	{
		this.timeOptions = timeOptions;
	}
	
	@action setSelectedTimeOption(timeOptionName)
	{
		if (timeOptionName === TakipiPickersTimeframeOptions.CUSTOM)
		{
			this.clearSelectedTimeOption();
			this.setCustomTimeOptionSelected(true);
		}
		else
		{
			let capitalizedOptionName = capitalizeFirstLetter(timeOptionName.toLowerCase());
			
			this.timeOptions.forEach(option => {
				option.isSelected = (option.name === capitalizedOptionName);
			});
		}
	}
	
	@action clearSelectedTimeOption()
	{
		this.timeOptions.forEach(option => {
			option.isSelected = false;
		});
	}
	
	getSelectedTimeOption()
	{
		for (let i = 0; i < this.timeOptions.length; i++)
		{
			if (this.timeOptions[i].isSelected)
			{
				return this.timeOptions[i];
			}
		}
	}
	
	getCustomTimeOptionSelected()
	{
		return this.customPartSelected;
	}
	
	@action setCustomTimeOptionSelected(customPartSelected)
	{
		this.customPartSelected = customPartSelected;
		
		this.clearSelectedTimeOption();
	}
	
	@action hideDatePickers()
	{
		this.datePickerFromDisplayed = false;
		this.datePickerToDisplayed = false;
	}
	
	@action toggleFromDatePicker()
	{
		this.setCustomTimeOptionSelected(true);
		
		this.datePickerToDisplayed = false;
		
		this.datePickerFromDisplayed = !this.datePickerFromDisplayed;
	}
	
	@action toggleToDatePicker()
	{
		this.setCustomTimeOptionSelected(true);
		
		this.datePickerFromDisplayed = false;
		
		this.datePickerToDisplayed = !this.datePickerToDisplayed;
	}
	
	@action setCreateRefreshRateEl(createRefreshRateEl)
	{
		this.createRefreshRateEl = createRefreshRateEl;
	}
	
	@action setRefreshRates(refreshRates)
	{
		this.refreshRates = refreshRates;
	}
	
	@action setSelectedRefreshRate(refreshRate)
	{
		this.selectedRefreshRate = refreshRate;
	}
	
	setOnApplyButtonClickedHandler(handler)
	{
		this.onApplyButtonClickedHandler = handler;
	}
	
	setOnCancelButtonClickedHandler(handler)
	{
		this.onCancelButtonClickedHandler = handler;
	}
	
	getSelectedFromHour()
	{
		return this.getSelectedHour(this.fromTime, false);
	}
	
	getSelectedToHour()
	{
		return this.getSelectedHour(this.toTime, true);
	}
	
	getSelectedHour(time, roundUp)
	{
		return this.momentToHoursDropdownElement(this.roundToNearestHalfHour(moment(time), roundUp));
	}
}
