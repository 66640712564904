export default class ProvideFeedbackController {
	constructor(provideFeedbackManager, modalApi)
	{
		this.provideFeedbackManager = provideFeedbackManager;
		this.modalApi = modalApi;
	}
	
	closeDialog = () =>
	{
		this.modalApi.close();
	};
	
	sendFeedback = (rate, subject, feedback, contactMe) =>
	{
		this.provideFeedbackManager.sendFeedback(rate, subject, feedback, contactMe);
		
		this.closeDialog();
	};
}
