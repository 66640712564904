import {DateTimePickerController} from './dateTimePicker/DateTimePickerController';
import {DateTimePickerViewModel} from './dateTimePicker/DateTimePickerViewModel';
import {mountTakipiDateTimePicker} from './dateTimePicker/DateTimePickerView';
import ProvideFeedbackController from "./feedback/ProvideFeedbackController";
import ProvideFeedbackViewModel from "./feedback/ProvideFeedbackViewModel";
import {mountProvideFeedbackDialog} from "./feedback/ProvideFeedbackView";
import 'semantic-ui-css/style-wrapper.scss';

let createTakipiDateTimePicker = function(pickersManager, dashboardManager, popupContainerEl, createRefreshRateEl)
{
	let dateTimePickerViewModel = new DateTimePickerViewModel();
	
	let dateTimePickerController = new DateTimePickerController(dateTimePickerViewModel, pickersManager, dashboardManager, createRefreshRateEl);
	
	dateTimePickerController.init();
	
	mountTakipiDateTimePicker(dateTimePickerViewModel, popupContainerEl[0]);
	
	return dateTimePickerController;
};


let createProvideFeedbackDialog = function(feedbackManager, appContainerId, modalApi)
{
	const provideFeedbackController = new ProvideFeedbackController(feedbackManager, modalApi);
	
	const provideFeedbackViewModel = new ProvideFeedbackViewModel(provideFeedbackController);
	
	mountProvideFeedbackDialog(provideFeedbackViewModel, appContainerId);
	
	return provideFeedbackController;
}


export {createTakipiDateTimePicker, createProvideFeedbackDialog};
