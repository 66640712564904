import React from 'react';

import {TakipiPopupMenu} from "../../../../../util/TakipiPopupMenuView";

const getPopupExceedsVertically = (position ,numberOfItems) =>
{
	const windowHeight = window.innerHeight;
	const offsetFromPreset = 45;
	
	return (position + offsetFromPreset + (numberOfItems * 34)) > windowHeight;
};

const SidepanelPopupMenu = props =>
{
	return (
		<TakipiPopupMenu {...props} />
	);
};

const buildPopupMenu = (props, menuItems) =>
{
	let exceedVertically = getPopupExceedsVertically(props.position, menuItems.length);
	
	return (
		<SidepanelPopupMenu {...props}
							orientation={exceedVertically ? "top" : "bottom"}
							items={menuItems}/>
	);
};

const alertSettingsMenuItem = (hasAlerts, onClickHandler) =>
{
	return {
		imgClass:			"alertEditImg",
		title:				(hasAlerts ? "Edit Alerts" : "Add Alerts"),
		onPopupItemClicked:	onClickHandler
	};
};

const duplicateMenuItem = (isTimeBased, isLabelBased, onClickHandler) =>
{
	let tooltip = "";
	
	if (isTimeBased)
	{
		tooltip = "Duplicating views is not available on time-frame based views";
	}
	else if (isLabelBased)
	{
		tooltip = "This view can't be duplicated";
	}
	
	return {
		imgClass:			"viewDuplicateImg",
		title:				"Duplicate",
		onPopupItemClicked:	onClickHandler,
		disabled:			((isTimeBased) || (isLabelBased)),
		tooltip:			tooltip
	};
};

const favoriteMenuItem = (isFavorite, onClickHandler) =>
{
	return {
		imgClass:			"favoritesImg",
		title:				(isFavorite ? "Remove from Favorites" : "Add to Favorites"),
		onPopupItemClicked:	onClickHandler
	};
};

const deleteMenuItem = (onClickHandler) =>
{
	return {
		imgClass:			"viewDeleteImg",
		title:				"Delete",
		onPopupItemClicked:	onClickHandler
	};
};

const hideExistingEventsMenuItem = (onClickHandler) =>
{
	return {
		imgClass:			"hideEditImg",
		title:				"Hide Existing Events",
		onPopupItemClicked:	onClickHandler
	};
};

const shareMenuItem = (isPrivate, isAdmin, isImmutable, onClickHandler) =>
{
	let tooltip = "";
	
	if (isAdmin)
	{
		tooltip = "This view is an admin view and can't be set private";
	}
	else if (isImmutable)
	{
		tooltip = "This view is immutable and can't be changed";
	}
	
	return {
		imgClass:			(isPrivate ? "viewShareImg" : "viewUndoShareImg"),
		title:				(isPrivate ? "Share with Team" : "Set as Private"),
		onPopupItemClicked:	onClickHandler,
		disabled:			((isAdmin) || (isImmutable)),
		tooltip:			tooltip
	};
};

const editMenuItem = (title, isImmutable, onClickHandler) =>
{
	let tooltip = "";
	
	if (isImmutable)
	{
		tooltip = "This view is immutable and can't be changed";
	}
	
	return {
		imgClass:			"viewEditImg",
		title:				title,
		onPopupItemClicked:	onClickHandler,
		disabled:			isImmutable,
		tooltip:			tooltip
	};
};

export const DefaultPresetMenu = props =>
{
	let menuItems = [];
	
	menuItems.push(alertSettingsMenuItem(props.hasAlerts, props.viewModel.onPresetMenuAlertSettingsClickedHandler));
	
	if (props.canToggleFavorites)
	{
		menuItems.push(favoriteMenuItem(props.isFavorite, props.viewModel.onPresetMenuFavoriteClickedHandler));
	}
	
	menuItems.push(duplicateMenuItem(props.isTimeBased, props.isLabelBased, props.viewModel.onPresetMenuDuplicateClickedHandler));
	
	return buildPopupMenu(props, menuItems);
};

export const UserPresetMenu = props =>
{
	let menuItems = [];
	
	menuItems.push(editMenuItem("View Settings", props.isImmutable, props.viewModel.onPresetMenuEditClickedHandler));
	menuItems.push(alertSettingsMenuItem(props.hasAlerts, props.viewModel.onPresetMenuAlertSettingsClickedHandler));
	menuItems.push(favoriteMenuItem(props.isFavorite, props.viewModel.onPresetMenuFavoriteClickedHandler));
	menuItems.push(duplicateMenuItem(props.isTimeBased, props.isLabelBased, props.viewModel.onPresetMenuDuplicateClickedHandler));
	menuItems.push(shareMenuItem(props.isPrivate, props.isAdmin, props.isImmutable, props.viewModel.onPresetMenuShareClickedHandler));
	menuItems.push(deleteMenuItem(props.viewModel.onPresetMenuDeleteClickedHandler));
	
	return buildPopupMenu(props, menuItems);
};

export const AutoArchivePresetMenu = props =>
{
	let menuItems = [];
	
	menuItems.push(editMenuItem("Auto-Hide Settings", props.isImmutable, props.viewModel.onPresetMenuEditClickedHandler));
	
	if (getInternalFieldOrElse(props, ["presetData", "preset", "archivedCurrentEvents"], null) === false)
	{
		menuItems.push(hideExistingEventsMenuItem(props.viewModel.onPresetMenuHideExistingClickedHandler));
	}
	
	menuItems.push(favoriteMenuItem(props.isFavorite, props.viewModel.onPresetMenuFavoriteClickedHandler));
	menuItems.push(deleteMenuItem(props.viewModel.onPresetMenuDeleteClickedHandler));
	
	return buildPopupMenu(props, menuItems);
};
