import classNames from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import {AutoArchivePresetMenu, DefaultPresetMenu, UserPresetMenu} from "./SidePanelContextMenusView";

import '../SidePanel.scss';

@observer
export default class SidePanelPresetView extends React.Component
{
	constructor(props)
	{
		super(props);
		
		this.position = null;
		
		this.viewModel = this.props.viewModel;
	}
	
	onClick = (e) => 
	{
		e.stopPropagation();
		
		let node = this.props.node;
		let badgeName = this.props.badgeName; 
		
		this.viewModel.onPresetClickedHandler(node, badgeName);
	};
	
	onDropdownArrowClicked = (e) =>
	{
		e.stopPropagation();
		
		let node = this.props.node;
		
		this.position = e.target.getBoundingClientRect().bottom;
		
		this.viewModel.activeContextMenuPresetId = node.preset.backendId;
	};
	
	popupMenuCloseHandler = () =>
	{
		this.viewModel.activeContextMenuPresetId = "";
		
		this.position = null;
	};
	
	renderPopupMenu = (presetData) =>
	{
		if (presetData.ruleType === "alert")
		{
			if (presetData.presetType === "default")
			{
				let isTimeBased = presetData.hasTimeframeFilter();
				let isLabelBased = presetData.hasLabelFilter();
				
				return <DefaultPresetMenu viewModel={this.viewModel}
										  position={this.position}
										  presetData={presetData}
										  canToggleFavorites={this.props.canToggleFavorites}
										  isFavorite={this.props.isFavorite}
										  popupMenuCloseHandler={this.popupMenuCloseHandler}
										  isTimeBased={isTimeBased}
										  isLabelBased={isLabelBased}
										  hasAlerts={this.props.hasAlert} />;
			}
			else
			{
				return <UserPresetMenu viewModel={this.viewModel}
									   position={this.position}
									   presetData={presetData}
									   isFavorite={this.props.isFavorite}
									   popupMenuCloseHandler={this.popupMenuCloseHandler}
									   hasAlerts={this.props.hasAlert}
									   isPrivate={presetData.presetType === "private"}
									   isAdmin={presetData.presetType === "admin"}
									   isImmutable={presetData.isImmutable}/>;
			}
		}
		else if (presetData.ruleType === "autoArchive")
		{
			return <AutoArchivePresetMenu position={this.position}
										  viewModel={this.viewModel}
										  presetData={presetData}
										  isFavorite={this.props.isFavorite}
										  popupMenuCloseHandler={this.popupMenuCloseHandler} />;
		}
	};
	
	render()
	{
		let presetData = this.props.node;
		let showDropdownMenu = !this.viewModel.isUserViewer;
		
		return (
			<div onClick={this.onClick}
				 className={classNames('viewWrapper', {'hidden': this.props.isHidden})}>
				
				{((this.viewModel.activeContextMenuPresetId === presetData.preset.backendId) && 
				  (this.renderPopupMenu(presetData)))}
				
				<div className={classNames('view',
						{'selected' : this.props.isSelected},
						{'modified' : this.props.isModified},
						{'draggable': this.props.draggable})} 
					 viewid={presetData.preset.backendId} 
					 ruletype={presetData.ruleType}
				>
					<div className="viewHolder">
						
						{showDropdownMenu ? <div className="dropdownArrow" onClick={this.onDropdownArrowClicked}/> : ""}
						
						<div className="tooltipHolder">
							<div className="typeIndication" />
							<div data-tip data-place="top" data-for={presetData.preset.presetName}
								 className={classNames("viewName", {'hasAlert': this.props.hasAlert})}
								 viewname={presetData.preset.presetName}
							>
								{presetData.preset.displayName}
							</div>
							<div className={classNames('alertIcon', {'hasAlert': this.props.hasAlert, 'selected': this.props.isSelected})} />
							<ReactTooltip id={presetData.preset.presetName} absPos={{x:0}} arrowPos='left'
										  delayShow={1000} place='top' type='light' effect='solid'
							>
								<div>
									<h4>{presetData.preset.displayName}</h4>
									<p className='tooltipDescription'>{presetData.preset.presetDesc}</p>
								</div>
							</ReactTooltip>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
