import classNames from 'classnames';
import React from 'react';
import Tree from 'react-ui-tree';

const SidePanelTree = (props) =>
{
	return (
		<div className={classNames({'activeViewContainer':
					getInternalFieldOrElse(props, ["tree", "children", "length"], 0) > 0 && !props.tree.collapsed})}>
			<Tree {...props}
				  paddingLeft={0}
				  disableRootDrag={true}
				  disableHorizontalDrag={true}
			/>
		</div>
	);
};

export default SidePanelTree;
