import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import './TakipiScrollbar.scss';

export default class TakipiScrollbar extends React.Component
{
	constructor(props)
	{
		super(props);
	}
	
	render()
	{
		return (
			<Scrollbars {...this.props}
						className="takipiScrollbar"
						autoHideTimeout={300}
						autoHideDuration={300}
			/>
		);
	}
}
