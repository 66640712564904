import React from 'react';

import '../SidePanel.scss';

const SidePanelShowMoreSectionView = (props) =>
{
	const onClick = () =>
	{
		props.viewModel.onShowMoreClickedHandler(props.isCollapsed);
	};
	
	return (
		<div className="showMore sectionTitle"
			 onClick={onClick}
		>
			<span className="text">{props.isCollapsed ? "Show More" : "Show Less"}</span>
			<span className={"toggle" + (props.isCollapsed ? " closed" : "")}/>
		</div>
	);
};

export default SidePanelShowMoreSectionView;
