import {action} from 'mobx';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import SidePanelPresetView from './SidePanelPresetView';
import SidePanelTree from "../tree/SidePanelTreeView";

import '../SidePanel.scss';

@observer
export default class SidePanelSectionView extends React.Component
{
	constructor(props)
	{
		super(props);
		
		this.viewModel = props.viewModel;
	}
	
	@action onChangeOrder = (tree) =>
	{
		let newPresetIdsOrder = tree.children.map(presetData => presetData.preset.backendId);
	
		this.viewModel.onFavoritesOrderChangedHandler(newPresetIdsOrder);
	};
	
	render()
	{
		let tree = {
			root:		true,
			module:		this.props.name,
			collapsed:	this.props.isCollapsed,
			selected:	this.props.isSelected,
			children:	this.props.presets
		};
		
		let showCustomAction = !this.viewModel.isUserViewer;
		
		return (
			<div>
				<SidePanelTree
					tree={tree}
					onChange={this.onChangeOrder}
					disableDragging={!this.props.isSectionItemsDraggable}
					dragDelay={300}
					renderNode={(node) => {
						// A 'node' is a child that may be 'presetData' or 'customAction' or the root of a section 
						//
						if ((Object.keys(node).length === 0) || 
							((!node.leaf) &&
							 ((!node.children) || (node.children.length === 0))))
						{
							return <div/>;
						}
						
						if (node.leaf)
						{
							if (node.isAction)
							{
								if (showCustomAction)
								{
									return <SidePanelCustomAction
											onClick={node.onClick}
											text={node.text}
											isHidden={this.props.isHidden}
											className={node.className}
									/>
								}
								else
								{
									return <div/>
								}
							}
							else
							{
								return <SidePanelPresetView
										node={node}
										isSelected={this.isSelected(node)}
										isModified={this.isModified(node)}
										canToggleFavorites={node.canToggleFavorites()}
										isFavorite={node.isFavorite}
										hasAlert={node.hasAlert}
										isHidden={this.props.isHidden}
										badgeName={this.props.name}
										viewModel={this.viewModel}
										draggable={this.props.isSectionItemsDraggable}
								/>
							}
						}
						else
						{
							return <SidePanelBadge
									title={this.props.name}
									description={this.props.description}
									isCollapsed={this.props.isCollapsed}
									draggable={this.props.isSectionDraggable}
									onClick={this.viewModel.onBadgeClickedHandler}
									isHidden={this.props.isHidden}
									mainClassName={this.props.mainClassName}
									isSelected={this.props.isSelected}
							/>
						}
					}}
				/>
			</div>
		);
	}
	
	isSelected = (node) =>
	{
		if (!this.viewModel.selectedPresetData)
		{
			return false;
		}
		
		return ((this.viewModel.selectedPresetData.preset.backendId === node.preset.backendId) &&
				(this.viewModel.selectedBadgeName === this.props.name));
	};
	
	isModified = (node) =>
	{
		return ((this.isSelected(node)) &&
				(this.viewModel.isSelectedPresetModified));
	};
}


export const SidePanelBadge = (props) =>
{
	return (

		<div  data-tip data-for={props.title + '_tooltip'}
			  className={classNames(props.mainClassName, 'sectionTitle',
				{'hidden' : props.isHidden},
				{'selected' : (props.isSelected && props.isCollapsed)})}
			 onClick={() => {
				 props.onClick(props.title, props.isCollapsed);
			 }}
		>
			<div>
				<span className={classNames(props.mainClassName, 'icon', {'draggable': props.draggable},
					{'bdsevere': props.title === "Severe"}, {'bdapps': props.title === "Apps"})} />
				<span className="text ellipsis">{props.title}</span>

				<span className={classNames('arrow', {'close' : props.isCollapsed})} />
			</div>

			<ReactTooltip id={props.title + '_tooltip'}
						  className='tooltipHolder'
						  absPos={{x:180}} arrowPos='top'
						  delayShow={1000} place='right' type='light' effect='solid'>
				<div>
					<h4>{props.title}</h4>
					<p className='tooltipDescription'>{props.description}</p>
				</div>
			</ReactTooltip>

		</div>
	);
};


export const SidePanelCustomAction = (props) =>
{
	return (
		<div className={classNames("actionItem", props.className, {'hidden': props.isHidden})}
			 onClick={props.onClick}>
			{props.text}
		</div>
	);
};
