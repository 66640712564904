import {action, observable} from 'mobx';

export class SidePanelViewModel
{
	// Observables
	//
	@observable isHovered;
	@observable isShowMoreCollapsed;
	@observable selectedBadgeName;
	@observable selectedPresetData;
	@observable isSelectedPresetModified;
	@observable activeContextMenuPresetId;
	@observable sections;
	@observable isUserViewer;
	
	//	sections is an array of:
	//	{
	//		id:					string from DefaultBadgeIds
	//		name:				string from DefaultBadgeNames
	//		type:				string from SectionTypes
	// 		isCollapsed:		boolean
	// 		isUnderShowMore:	boolean
	// 		presets:			[ SidePanelPresetData ]
	//	}
	
	constructor(sidePanelController)
	{
		this.controller = sidePanelController;
		
		EventManagerInst.addEventListener(this.controller, "SERVICE_CHANGED", this.serviceChanged);
		EventManagerInst.addEventListener(this.controller, "SECTIONS_CHANGED", this.sectionsChanged);
		EventManagerInst.addEventListener(this.controller, "SHOW_MORE_COLLAPSED", this.showMoreCollapsed);
		EventManagerInst.addEventListener(this.controller, "SHOW_MORE_EXPANDED", this.showMoreExpanded);
		EventManagerInst.addEventListener(this.controller, "SELECTED_BADGE_CHANGED", this.selectedBadgeChanged);
		EventManagerInst.addEventListener(this.controller, "SELECTED_PRESET_CHANGED", this.selectedPresetChanged);
		EventManagerInst.addEventListener(this.controller, "SELECTED_PRESET_MODIFIED_CHANGED", this.selectedPresetModifiedChanged);
		EventManagerInst.addEventListener(this.controller, "ACTIVE_MENU_CHANGED", this.activeMenuChanged);
		EventManagerInst.addEventListener(this.controller, "IS_USER_VIEWER_SET", this.isUserViewerChanged);
		
		this.resetObservables();
	}
	
	resetObservables()
	{
		this.isHovered					= false;
		this.isShowMoreCollapsed		= false;
		this.selectedBadgeName			= "";
		this.selectedPresetData			= null;
		this.isSelectedPresetModified	= false;
		this.activeContextMenuPresetId	= "";
		this.sections					= [];
		this.isUserViewer			= false;
	}
	
	// Controller Event Handlers
	//
	@action serviceChanged = () =>
	{
		this.resetObservables();
	};
	
	@action sectionsChanged = () =>
	{
		this.sections = this.controller.getSections();
	};
	
	@action showMoreCollapsed = () =>
	{
		this.isShowMoreCollapsed = true;
	};
	
	@action showMoreExpanded = () =>
	{
		this.isShowMoreCollapsed = false;
	};
	
	@action selectedBadgeChanged = () =>
	{
		this.selectedBadgeName = this.controller.getSelectedBadgeName();
	};
	
	@action selectedPresetChanged = () =>
	{
		this.selectedPresetData = this.controller.getSelectedPresetData();
	};
	
	@action selectedPresetModifiedChanged = () =>
	{
		this.isSelectedPresetModified = this.controller.getIsSelectedPresetModified();
	};
	
	@action activeMenuChanged = () =>
	{
		this.activeContextMenuPresetId = this.controller.getActiveContextMenuPresetId();
	};
	
	@action isUserViewerChanged = () =>
	{
		this.isUserViewer = this.controller.getIsUserViewer();
	};
	
	// View Event Handlers
	//
	onBadgeDragEndedHandler = (badgeIdOrder) =>
	{
		this.controller.updateBadgesOrder(badgeIdOrder);
	};
	
	onShowMoreClickedHandler = (isShowMoreCollapsed) =>
	{
		this.controller.toggleShowMore(isShowMoreCollapsed);
	};
	
	onBadgeClickedHandler = (badgeName, isCollapsed) =>
	{
		this.controller.toggleSection(badgeName, isCollapsed);
	};
	
	onFavoritesOrderChangedHandler = (presetIdsOrder) =>
	{
		this.controller.favoritesOrderChanged(presetIdsOrder);
	};
	
	onPresetClickedHandler = (presetData, badgeName) =>
	{
		this.controller.presetClicked(presetData, badgeName);
	};
	
	onPresetMenuEditClickedHandler = (presetData) =>
	{
		this.controller.viewEditClicked(presetData);
	};
	
	onPresetMenuAlertSettingsClickedHandler = (presetData) =>
	{
		this.controller.viewAlertSettingsClicked(presetData);
	};
	
	onPresetMenuFavoriteClickedHandler = (presetData) =>
	{
		this.controller.toggleFavoriteClicked(presetData);
	};
	
	onPresetMenuDuplicateClickedHandler = (presetData) =>
	{
		this.controller.viewDuplicateClicked(presetData);
	};
	
	onPresetMenuShareClickedHandler = (presetData) =>
	{
		this.controller.viewShareClicked(presetData);
	};
	
	onPresetMenuDeleteClickedHandler = (presetData) =>
	{
		this.controller.viewDeleteClicked(presetData);
	};
	
	onPresetMenuHideExistingClickedHandler = (presetData) =>
	{
		this.controller.viewHideExistingClicked(presetData);
	};
}
