import React, { Component } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import ReactDOM from "react-dom";
import Dropdown from "react-dropdown";
import { Button, Checkbox } from "semantic-ui-react";

import "./FeedbackDropdown.scss";
import "./ProvideFeedbackView.scss";

@observer
class ProvideFeedbackContent extends Component
{
	constructor(props)
	{
		super(props);
	}

	emojis = ["😡", "😩", "😐", "😊", "😍"];

	feedbackOptions = ["Performance", "Usability", "Look and feel", "Bugs", "Other"];

	textAreaPlaceholder = "Tell us more about your feedback and how we can help with it";

	handleRateSelected = (index) =>
	{
		const { viewModel } = this.props;
		viewModel.rateSelected(index);
	};

	render()
	{
		const provideFeedbackContent = this;
		const { viewModel } = this.props;
		const { rating } = viewModel;

		return (
			<div className="provideFeedbackView">
				<main>
					<div className="experienceContainer">
						<div className="experienceTitle">General experience:</div>
						<div className="emojisContainer">
							{this.emojis.map((item, index) => (
								<div
									key={index}
									style={{ fontSize: 20 }}
									onClick={() => {
										this.handleRateSelected(index);
									}}
									className={classNames({
										emojiItem: true,
										unselectedItem: ((isNumber(rating)) && (rating !== index))
									})}
								>
									{item}
								</div>
							))}
						</div>
					</div>
					<div className="feedbackContainer">
						<div className="feedbackSubjectTitle">Feedback subject:</div>
						<Dropdown
							arrowClassName="arrow-open"
							options={this.feedbackOptions}
							placeholder="Select an option"
							value={viewModel.subject}
							onChange={item => viewModel.subjectChanged(item.value)}
						/>
					</div>
					<div className="tellUsMoreContainer">
						<div className="tellUsMoreTitle">Tell us about it:</div>
						<div>
							<textarea
								rows="6"
								cols="51"
								type="text"
								maxlength="500"
								className="feedbackArea"
								placeholder={this.textAreaPlaceholder}
								onInput={e => viewModel.feedbackTextChange(e.target.value)}
								onFocus={e => {
									e.target.placeholder = "";
								}}
								onBlur={e => {
									e.target.placeholder = provideFeedbackContent.textAreaPlaceholder;
								}}
							/>
						</div>
					</div>
					<div className="dialogSeparator" />
				</main>
				<footer>
					<div className="actionsContainer">
						<Checkbox
							className="contactMe"
							onChange={(e, data) => viewModel.contactMeChanged(data.checked)}
							checked={viewModel.contactMe}
							label="Allow OverOps to contact me about my feedback"
						/>
						<div className="buttonsContainer">
							<Button
								inverted
								className="cancelButton"
								onClick={() => viewModel.closeDialog()}
							>
								Cancel
							</Button>
							<Button
								primary
								disabled={!viewModel.sendEnabled}
								className="sendButton"
								onClick={() => viewModel.sendFeedback()}
							>
								Send
							</Button>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}

export const mountProvideFeedbackDialog = function(provideFeedbackViewModel, appContainerId)
{
	ReactDOM.render(<ProvideFeedbackContent viewModel={provideFeedbackViewModel} />, appContainerId);
};
