import { action, computed, observable } from "mobx";

export default class ProvideFeedbackViewModel
{
	constructor(controller)
	{
		this.controller = controller;
	}

	@observable rating;
	@observable subject;
	@observable contactMe;
	@observable feedback;

	@computed
	get sendEnabled()
	{
		return ((isNumber(this.rating)) ||
				(this.feedback));
	}

	@action
	rateSelected = (rating) =>
	{
		this.rating = rating;
	};

	subjectChanged = (subject) =>
	{
		this.subject = subject;
	};

	contactMeChanged = (contactMe) =>
	{
		this.contactMe = contactMe;
	};

	feedbackTextChange = (feedback) =>
	{
		this.feedback = feedback;
	};

	sendFeedback = () =>
	{
		const rating = ((isNumber(this.rating)) ? (this.rating + 1) : (0));

		this.controller.sendFeedback(rating, this.subject, this.feedback, this.contactMe);
	};

	closeDialog = () =>
	{
		this.controller.closeDialog();
	};
}
