import React from 'react';

import './TakipiPopupMenu.scss';

export class TakipiPopupMenu extends React.Component
{
	constructor(props)
	{
		super(props);
		
		this._handleClickOutside = this.handleClickOutside.bind(this);
	}
	
	componentWillMount()
	{
		document.addEventListener('click', this._handleClickOutside, false);
	}
	
	componentWillUnmount()
	{
		document.removeEventListener('click', this._handleClickOutside, false);
	}
	
	handleClickOutside()
	{
		this.props.popupMenuCloseHandler();
	}
	
	render()
	{
		return (
			<div>
				<div className="checkboxPopup takipiPopup contextPopup takipiPopupMenu popupOrientation"
					 vertical={this.props.orientation}
				>
					<span className="arrow takipiPopupMenuArrow"
						  dir={this.props.orientation === "top" ? "bottom" : "top"}
					/>
					<div className="main firstComponent noFooter">
						<div className="itemGroups">
							<div className="itemGroup">
								
								{this.props.items.map(item =>
									<a className={"popupItem" + (item.disabled ? " disabled" : "")}
									   onClick={(e) => {
											e.stopPropagation();
											
											if (item.disabled)
											{
												return;
											}
											
											item.onPopupItemClicked(this.props.presetData);
									 	}}>
										<div title={item.tooltip}
											 style={{"width": "100%"}}
										>
											<div className={"itemImage " + item.imgClass}/>
											<div className="itemText">{item.title}</div>
										</div>
									</a>
								)}
								
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
