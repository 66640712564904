import React from 'react';
import ReactDOM from "react-dom";
import classNames from "classnames";
import {observer} from 'mobx-react';

import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';

import moment from 'moment';

import './calendar/react-datepicker.scss';
import './DateTimePicker.scss';
import './Dropdown.scss';

@observer
export class DateTimePicker extends React.Component
{
	constructor(props)
	{
		super(props);
		
		this.viewModel = props.viewModel;
	}
	
	handleQuickOptionChanged = (event) =>
	{
		this.enableQuickOptionsPart();
		
		this.viewModel.setSelectedTimeOption(event.target.innerText);
	};
	
	enableQuickOptionsPart = () =>
	{
		this.viewModel.setCustomTimeOptionSelected(false);
		this.viewModel.hideDatePickers();
		
		this.forceUpdate();
	};
	
	handleRefreshRateChanged = (refreshRate) =>
	{
		this.viewModel.setSelectedRefreshRate(refreshRate);
	};
	
	applyButtonClicked = () =>
	{
		this.viewModel.onApplyButtonClickedHandler();
	};
	
	cancelButtonClicked = () =>
	{
		this.viewModel.onCancelButtonClickedHandler();
	};
	
	onTimePickerUpperBodyClicked = (e) =>
	{
		let target = e.target;
		
		if ((target != null) &&
			(!target.className.contains("react-datepicker")) &&
			(!target.className.contains("Dropdown-")))
		{
			this.viewModel.hideDatePickers();
		}
	};
	
	toggleFromDatePicker = (e) =>
	{
		this.viewModel.toggleFromDatePicker();
		
		e.stopPropagation()
	};
	
	toggleToDatePicker = (e) =>
	{
		this.viewModel.toggleToDatePicker();
		
		e.stopPropagation()
	};
	
	enableCustomRangePart = () =>
	{
		this.viewModel.setCustomTimeOptionSelected(true);
		
		this.forceUpdate();
	};
	
	handleFromDateChanged = (event) =>
	{
		this.viewModel.setFromTime(event);
	};
	
	handleToDateChanged = (event) =>
	{
		this.viewModel.setToTime(event);
	};
	
	handleFromHourChanged = (event) =>
	{
		this.viewModel.fromHourChanged(event.value.clone());
		
		this.forceUpdate();
	};
	
	handleToHourChanged = (event) =>
	{
		this.viewModel.toHourChanged(event.value.clone());
		
		this.forceUpdate();
	};
	
	fromSetToNowButtonClicked = (e) =>
	{
		if (this.viewModel.getCustomTimeOptionSelected())
		{
			this.viewModel.setFromTime(moment());
		}
		else
		{
			this.enableCustomRangePart();
		}
		
		e.stopPropagation();
	};
	
	toSetToNowButtonClicked = (e) =>
	{
		if (this.viewModel.getCustomTimeOptionSelected())
		{
			this.viewModel.setToTime(moment());
		}
		else
		{
			this.enableCustomRangePart();
		}
		
		e.stopPropagation();
	};
	
	render()
	{
		let dateTimePicker = this;
		let FULL_DATE_FORMAT = 'MM/DD/YYYY';
		
		return(
			<div className="timePickerDialog unselectable" tabIndex="-1">
				<div className="timePickerUpperBody" onClick={this.onTimePickerUpperBodyClicked}>
					<div className="quickOptions">
						<span className={classNames('text-header', {'disabled' : (this.viewModel.getCustomTimeOptionSelected())})}
							  onClick={this.enableQuickOptionsPart}>Quick Options</span>
						<div className={classNames('quickOptionsHolder', {'disabled' : (this.viewModel.getCustomTimeOptionSelected())})}>
							{ this.viewModel.timeOptions.map(timeOption => {
								return (
									<div>
										<span className={classNames('quickOption', {'selected' : (timeOption.isSelected)})}
											  onClick={dateTimePicker.handleQuickOptionChanged}>{capitalizeFirstLetterAllWords(timeOption.name)}</span>
											
											{ (timeOption.name !== "Custom") &&
											<span className={((timeOption.name === "Last 12 hours") || (timeOption.name === "Last 48 hours")) ?
													'midSectionsLine' : 'sectionLine'} />
											}
									</div>
								);
							})}
						</div>
					</div>
					<div className="dialogSeparator" />
					<div className="customRangeHolder">
						<span className={classNames('text-header', {'disabled' : (!this.viewModel.getCustomTimeOptionSelected())})}
							  onClick={this.enableCustomRangePart}>Custom Range</span>
						<div className={classNames('customRangeBody', {'disabled' : (!this.viewModel.getCustomTimeOptionSelected())})}>
							<div className="customRange"
								 type="from">
								<div className="customRangeHeaderHolder">
									<span className="text-fromToLabels">From:</span>
									<div className="setToNow" 
										 onClick={this.fromSetToNowButtonClicked}>
										Set to Now
									</div>
								</div>
								<div className="timeInputHolder"
									 onClick={this.enableCustomRangePart}>
									<input className="timeInput"
										   value={this.viewModel.fromTime.format(FULL_DATE_FORMAT)}
										   onClick={this.toggleFromDatePicker}
										   readOnly />
									<div className="calendarImg"
										 onClick={this.toggleFromDatePicker} />
									<Dropdown options={this.viewModel.hours}
											  value={this.viewModel.getSelectedFromHour()}
											  onChange={this.handleFromHourChanged} />
								</div>
								<div className={classNames('datePickerHolder', {'open' : (this.viewModel.datePickerFromDisplayed)})}>
									<DatePicker inline
												locale="en-us"
												minDate={moment().subtract(90, 'days')}
												maxDate={this.viewModel.toTime}
												selected={this.viewModel.fromTime}
												onChange={this.handleFromDateChanged}
												showDisabledMonthNavigation/>
								</div>
							</div>
							
							<div className="spacer" />
							
							<div className="customRange"
								 type="to">
								<div className="customRangeHeaderHolder">
									<span className="text-fromToLabels">To:</span>
									<div className="setToNow"
										 onClick={this.toSetToNowButtonClicked}>
										Set to Now
									</div>
								</div>
								<div className="timeInputHolder"
									 onClick={this.enableCustomRangePart}>
									<input className="timeInput"
										   value={this.viewModel.toTime.format(FULL_DATE_FORMAT)}
										   onClick={this.toggleToDatePicker}
										   readOnly />
									<div className="calendarImg"
										 onClick={this.toggleToDatePicker} />
									<Dropdown options={this.viewModel.hours}
											  value={this.viewModel.getSelectedToHour()}
											  onChange={this.handleToHourChanged} />
								</div>
								<div className={classNames('datePickerHolder', {'open': (this.viewModel.datePickerToDisplayed)})}>
									<DatePicker inline
												locale="en-us"
												minDate={this.viewModel.fromTime}
												maxDate={moment()}
												selected={this.viewModel.toTime}
												onChange={this.handleToDateChanged}
												showDisabledMonthNavigation/>
								</div>
							</div>
							
							<div className={classNames('message', {'show': (this.viewModel.isMessageShown)})}>
								<div className="icon"/>
								<span className="text">'From' date must be earlier than 'To' date</span>
							</div>
						</div>
					</div>
				</div>
				<div className="dialogSeparator" />
				<div className="timePickerFooter">
					{
						this.viewModel.createRefreshRateEl &&
						<span className="refreshRateHolder">
							<span className="text-refreshEveryLabel">Refresh event list every:</span>
							<Dropdown options={this.viewModel.refreshRates}
									  value={this.viewModel.selectedRefreshRate}
									  onChange={this.handleRefreshRateChanged}/>
						</span>
					}
					<div className={classNames('cancelBtn', {'wide': (!this.viewModel.createRefreshRateEl)})}
						 onClick={this.cancelButtonClicked}>Cancel</div>
					<div className={classNames('bigBtn', 'takipiDialogBtn', 'clickable', 'applyBtn')}
						 onClick={this.applyButtonClicked}>Apply</div>
				</div>
			</div>
		);
	}
}


export const mountTakipiDateTimePicker = function(dateTimePickerViewModel, popupContainerEl)
{
	ReactDOM.render(<DateTimePicker viewModel={dateTimePickerViewModel}/>, popupContainerEl);
	
	popupContainerEl.children[0].focus();
};
