import {observer} from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import {toJS} from 'mobx';
import Tree from 'react-ui-tree';

import SidePanelShowMoreSectionView from "./sections/SidePanelShowMoreSectionView";
import SidePanelSectionView from './sections/SidePanelSectionView';
import TakipiScrollbar from "../../../../util/scrollbar/TakipiScrollbarView";

import './SidePanel.scss';

@observer
export class SidePanelView extends React.Component
{
	constructor(props)
	{
		super(props);
		
		this.viewModel = props.viewModel;
	}
	
	onMouseEnterHandler = () =>
	{
		this.viewModel.isHovered = true;
	};
	
	onMouseLeaveHandler = () =>
	{
		this.viewModel.isHovered = false;
	};
	
	getBadgesOrder = (tree) =>
	{
		return tree.children.map(badge => badge.id);
	};
	
	render()
	{
		// GUY: toJS deep clones an observable to a plain object
		// which causes MobX to react to any change inside it
		//
		let tree = {
			module: "ROOT",
			root: true,
			children: toJS(this.viewModel.sections)
		};
		
		return (
			<TakipiScrollbar autoHide={!this.viewModel.isHovered}>
				<div onMouseEnter={this.onMouseEnterHandler}
					 onMouseLeave={this.onMouseLeaveHandler}
				>
					<Tree tree={tree}
						  renderNode={(node) => 
							  <NodeRenderer node={node}
											viewModel={this.viewModel} />
						  }
						  paddingLeft={0}
						  dragDelay={300}
						  disableDragging={false}
						  disableRootDrag={true}
						  disableHorizontalDrag={true}
						  dragFirstLevelOnly={true}
						  onDragStarted={() => {}}
						  onDragEnded={(tree) => {
							  this.viewModel.onBadgeDragEndedHandler(this.getBadgesOrder(tree));
						  }}
						  onChange={(tree) => {
							  // GUY: somehow this contributes to a smoother experience after dragging
							  //
							  this.forceUpdate();
						  }}
					/>
				</div>
			</TakipiScrollbar>
		);
	}
}


export const NodeRenderer = observer(({node, viewModel}) =>
{
	if (node.module === "ROOT")
	{
		return <div/>;
	}
	
	return (
		<div className="sectionContainer">
			{(node.type === SectionTypes.VIEW_TYPE_NAME) &&
			 <SidePanelSectionView mainClassName={node.id.toLowerCase()}
								   name={node.name}
								   description={node.badge_desc}
								   isHidden={node.isUnderShowMore && viewModel.isShowMoreCollapsed}
								   isSelected={node.name === viewModel.selectedBadgeName}
								   isCollapsed={node.isCollapsed}
								   isSectionItemsDraggable={node.id.toUpperCase() === DefaultBadgeIds.BADGES_DEFAULT_FAVORITES_ID}
								   isSectionDraggable={node.id.toUpperCase() !== DefaultBadgeIds.BADGES_DEFAULT_FAVORITES_ID}
								   presets={node.presets}
								   viewModel={viewModel}/>
			}
			
			{(node.type === SectionTypes.SHOW_MORE_TYPE_NAME) &&
			 <SidePanelShowMoreSectionView isCollapsed={viewModel.isShowMoreCollapsed}
										   viewModel={viewModel}/>
			}
		</div>
	);
});


export const mountTakipiSidePanel = function(sidePanelViewModel, elementId)
{
	ReactDOM.render(<SidePanelView viewModel={sidePanelViewModel} />, elementId);
};
